<template>
<Layout>
  <v-main class="pt-4 pb-10 mb-5" >
    <section id="slide" class="px-3">
      <section-header
        header="El éxito de su evento es nuestro principal objetivo!"
        sub-header="AJ Producciones y Eventos, S.C."
      />
    </section>
    <v-container>
      <v-row>
        <v-col>
          <h2 class="font-weight-black">
            Misión
          </h2>
          <p>
            Organizar eventos de alta calidad para nuestros clientes a nivel global, ajustándonos a sus necesidades de manera profesional y asertiva, con el fin de contribuir al posicionamiento positivo  de su imagen corporativa ante sus audiencias clave.
          </p>
          <h2 class="font-weight-black">
            Visión
          </h2>
          <p>
            Ser una empresa aliada para nuestros clientes y proveedores, en aras de establecer con éstos relaciones a largo plazo, sobre la base de la confianza y la excelencia.
          </p>
          <h2 class="font-weight-black">Valores</h2>
          <v-row>
            <v-col cols="12" sm="4">
              <p class="mt-2">
                <v-icon >mdi-marker-check</v-icon> Confianza.
              </p>
              <p class="mt-2">
                <v-icon >mdi-marker-check</v-icon> Honestidad.
              </p>
              <p class="mt-2">
                <v-icon >mdi-marker-check</v-icon> Ética profesional.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Respeto.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Trabajo en equipo.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Capacidad de adaptación.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Creatividad.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Compañerismo.
              </p>
              <p>
                <v-icon >mdi-marker-check</v-icon> Transparencia.
              </p>
            </v-col>
             <v-col cols="12" sm="8">
               <v-img src="/default/org3.jpg"/>
             </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </v-main>
  <v-carousel hide-delimiters cycle progress progress-color="secondary" >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</Layout>
</template>
<script>
  export default {
    head() {
    return {
      title: 'Nosotros',
      description: ' Consultoria, producción y organización profesional de eventos científicos y corporativos'
    }
  },
  name: 'nosotros',
  data () {
      return {
        items: [
          {
            src: '/static/about/banner-about-1.jpg',
          },
          {
            src: '/static/about/banner-about-2.jpg',
          },
        ],
      }
    },
  }
</script>



